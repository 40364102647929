import { DmgTemplates } from '../pages/Admin/DmgTemplates';

function DmgRoute() {
  return <DmgTemplates />;
}

export const Component = DmgRoute;

export function clientLoader() {
  return null;
}
